import { useState } from 'react';
import { useSelector } from 'react-redux'
import Slider from "react-slick";
const ProductImages = () => {

    const product = useSelector(state => state.productpage.data);
    const packaging = useSelector(state => state.productpage.packaging);

    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    if (typeof (product) !== 'undefined' && product.length !== 0) {
        let images = [];
        let mainimage = [];
        let slick = 's1';

        let slideimagescount = 0;

        if (packaging && typeof (packaging.image) !== 'undefined' && packaging.image !== null) {
            images.push(packaging.image);
            mainimage.push(packaging.image);
            slick = 's2';
        }
        product.products['product-bundle-medias'].forEach((i) => {
            if (i['media-kind'] === 'slideelement') {
                images.push(i);
                slideimagescount++;
            }
            if (i['media-kind'] === 'mainelement') {
                mainimage.push(i);
            }
        });

        let pics;

        if (images.length && slideimagescount >= 1) {
            pics = images.map((image) => (
                <img key={image.position} src={global.config.apidata.url + '/' + image['relative-path']} alt={image['alt']} />
            ));
        }
        else {
            pics = mainimage.map((image) => (
                <img key={image.position} src={global.config.apidata.url + '/' + image['relative-path']} alt={image['alt']} />
            ));
        }

        const mobilesettings = [{
            breakpoint: 767,
            settings: {
                fade: false,
                dots: true
            }
        }]

        return (
            <>
                <div className="col-lg-4 col-md-5 col-sm-5 col-xs-12">
                    <div className="productslider" key={slick}>
                        <Slider className="slider"
                            asNavFor={slider2}
                            ref={(slider) => setSlider1(slider)}
                            arrows={false}
                            fade={true}
                            responsive={mobilesettings}

                        >
                            {pics}
                        </Slider>
                        <Slider className="slider small hidden-xs"
                            asNavFor={slider1}
                            ref={(slider) => setSlider2(slider)}
                            arrows={false}
                            focusOnSelect={true}
                            variableWidth={false}
                            slidesToShow={5}
                            infinite={true}
                            centerMode={true}
                            centerPadding={'0px'}
                        >
                            {pics}
                        </Slider>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductImages;
