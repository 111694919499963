import HelperService from "../../../services/helper.service";
import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';

const ProductOptionsPackaging = ({ type, mobile, setCouponActivePrice, setselect = '' }) => {

    const dispatch = useDispatch();
    const distribution = useSelector(state => state.productpage.distributionproducts);
    const packagingid = useSelector(state => state.productpage.packaging.id);

    function changePackagingStates(id, price, description, shippingmethods, sliderimage) {
        let shippingid = shippingmethods[0].id;
        let shippingprice = shippingmethods[0].price['gross-amount-value'].amount;
        let shippingdescription = shippingmethods[0].description;
        let shippingtype = shippingmethods[0].type;
        dispatch(ProductPageStore.setPackaging({
            'id': id,
            'name': description,
            'price': price,
            'image': sliderimage
        }));
        dispatch(ProductPageStore.setShipping({
            'id': shippingid,
            'name': shippingdescription,
            'price': shippingprice,
            'type': shippingtype
        }));
        dispatch(CartStore.setCartPackaging(id));
        dispatch(CartStore.setCartShipping(shippingid));
        dispatch(ProductPageStore.setCoupon());
        dispatch(CartStore.setCartCouponCode(null));
        setCouponActivePrice(null);
        if (setselect) {
            setselect('hidden');
        }
    }

    const packagingBoxes = distribution.map((product) => {
        let id = product['shipping-packaging'].id;
        let description = product['shipping-packaging'].description;
        let sliderimage = product['shipping-packaging']['packaging-logo'] ? product['shipping-packaging']['packaging-logo'] : null;
        let shippingmethods = product['shipping-packaging']['shipping-method-products'];
        let rawprice = product['shipping-packaging']['price']['gross-amount']['amount'];
        let price = rawprice !== 0 ? HelperService.formatPrice(rawprice) : '';
        let selectoption;
        let image;
        if (type !== 'select') {
            if (product['shipping-packaging']['packaging-icon']) {
                let imagepath = global.config.apidata.url + '/' + product['shipping-packaging']['packaging-icon']['relative-path'];
                let imagealt = product['shipping-packaging']['packaging-icon']['alt'];
                image = <img className="shippingicon" src={imagepath} alt={imagealt} />
            }
            selectoption = mobile !== 'yes' ? description : description + ' ' + price;
        }
        else {
            selectoption = description + ' ' + price;
        }
        return (
            <button
                type="button"
                onClick={() => changePackagingStates(id, rawprice, description, shippingmethods, sliderimage)}
                className={(packagingid === id ? 'activ' : '')}
                key={id}
            >
                {image}
                {selectoption}
            </button>
        )
    });

    let view;

    if (type === 'select') {
        view =
            <>
                {packagingBoxes}
            </>
    }
    else {
        let classes = 'boxview';
        if (mobile !== 'yes') {
            classes = "hidden-md hidden-sm hidden-xs";
        }

        view =
            <div className={classes}>
                <h3>Verpackung</h3>
                <div className="checkboxes packaging">
                    {packagingBoxes}
                </div>
            </div>
    }
    return (
        <>
            {view}
        </>
    );

}

export default ProductOptionsPackaging;
